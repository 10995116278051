import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { OrganizationUser, OrganizationUserNew } from "@/views/organization/organization";
import type { AxiosResponse } from "axios";

const URL = (organizationId: string | number) => `organizations/${organizationId}/users`;
const ENTITY_NAME = "User";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<OrganizationUser>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getOrganizationUsers = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL(params.organizationId);
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
// const getOrganizationUserById = (id, config: PagingApiAndNotifierConfig = {}) => {
// const successNotifCb = (options) => {
//   options.hide = true;
//   options.fetched = ENTITY_NAME;
//   config?.successNotifCb?.(options);
// };
// const errorNotifCb = (options: NotifyOptionsError) => {
//   options.fetched = ENTITY_NAME;
//   config?.errorNotifCb?.(options)
// };
//   return api.get(URL + "/" + id, {
// successNotifCb,
// errorNotifCb,
//   });
// };

const getNewOrganizationUser = async () => {
  //optionally make any other changes as necessary before returning.
  return { data: {} } as AxiosResponse<OrganizationUserNew, any>;
};

const createOrganizationUser = (organizationUser: OrganizationUser, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.title = `${ENTITY_NAME} Added`;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(organizationUser.organizationId), organizationUser, {
    successNotifCb,
    errorNotifCb,
  });
};

// const updateOrganizationUser = (organizationUser, config: PagingApiAndNotifierConfig = {}) => {
// const successNotifCb = (options) => {
//   options.updated = ENTITY_NAME;
//   config?.successNotifCb?.(options);
// };
// const errorNotifCb = (options: NotifyOptionsError) => {
//   options.updated = ENTITY_NAME;
//   config?.errorNotifCb?.(options)
// };
//   return api.put(URL + "/" + organizationUser.id, organizationUser, {
// successNotifCb,
// errorNotifCb,
//   });
// };

/**
 * @param {OrganizationUser} organizationUser This cannot be a 'ref' to a organizationUser
 */
// const saveOrganizationUser = (organizationUser, config: PagingApiAndNotifierConfig = {}) => {
//   if (organizationUser.id) {
//     return updateOrganizationUser(organizationUser, config);
//   } else {
//     return createOrganizationUser(organizationUser, config);
//   }
// };

const deleteOrganizationUserById = (ou: OrganizationUser, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.title = `${ENTITY_NAME} Removed`;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(ou.organizationId) + "/" + ou.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteOrganizationUserById,
  getOrganizationUsers,
  // getOrganizationUserById,
  getNewOrganizationUser,
  createOrganizationUser,
  // updateOrganizationUser,
  // saveOrganizationUser,
};
