import {
  addressFromFormkit,
  addressToFormkit,
  plainAddressFromFormkit,
  plainAddressToFormkit,
} from "@modernary/mui/components/fk";

import { FacilitySite } from "@/views/facilitySite/facilitySite";

export default {
  addAuthRequestFields,
  getInitialData,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: FacilitySite) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}

function getInitialData() {
  const result = {} as FacilitySite; //TODO:TS
  return addAuthRequestFields(result);
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: FacilitySite) {
  addressToFormkit(data, "mail");
  plainAddressToFormkit(data);
}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: any) {
  //TODO:TS
  addressFromFormkit(data as FacilitySite, "mail");
  plainAddressFromFormkit(data as FacilitySite);
}
