import { RouterView } from "vue-router";
import EntityAudits from "@/components/EntityAudits.vue";

import { EntityDocuments } from "@modernary/mui/components/document";
import { EntityNotes } from "@modernary/mui/components/note";

const names = {
  complaints: "Complaints",
  complaintList: "ComplaintList",
  complaintNew: "ComplaintNew",
  complaintBase: "ComplaintBase",
  complaintView: "ComplaintView",
  complaintEdit: "ComplaintEdit",
  complaintInvestigations: "ComplaintInvestigations",
  complaintInvestigationEdit: "ComplaintInvestigationEdit",
  complaintInvestigationNew: "ComplaintInvestigationNew",
  complaintNotes: "ComplaintNotes",
  complaintNoteEdit: "ComplaintNoteEdit",
  complaintNoteNew: "ComplaintNoteNew",
  complaintDocuments: "ComplaintDocuments",
  complaintDocumentEdit: "ComplaintDocumentEdit",
  complaintDocumentNew: "ComplaintDocumentNew",
  complaintAudit: "ComplaintAudit",
};

export { names as complaintRouteNames };

const complaintRoutes = [
  {
    path: "/complaints",
    name: names.complaints,
    component: {
      render: () => <RouterView />,
    },
    redirect: { name: names.complaintList },
    children: [
      {
        path: "list",
        name: names.complaintList,
        meta: { title: "Complaints" },
        component: () => import("@/views/complaint/ComplaintList.vue"),
      },
      {
        path: "new",
        component: () => import("@/views/complaint/ComplaintBase.vue"),
        redirect: { name: names.complaintNew },
        props: true,
        children: [
          {
            path: "",
            name: names.complaintNew,
            meta: { title: "New Complaint", isNew: true },
            component: () => import("@/views/complaint/ComplaintEdit.vue"),
          },
        ],
      },
      {
        path: ":id(\\d+)",
        name: names.complaintBase,
        component: () => import("@/views/complaint/ComplaintBase.vue"),
        redirect: { name: names.complaintView },
        children: [
          {
            path: "view",
            name: names.complaintView,
            meta: { title: "Complaint View" },
            component: () => import("@/views/complaint/ComplaintView.vue"),
          },
          {
            path: "edit",
            name: names.complaintEdit,
            meta: { title: "Complaint Edit", isForm: true },
            component: () => import("@/views/complaint/ComplaintEdit.vue"),
          },
          {
            path: "investigations",
            name: names.complaintInvestigations,
            meta: { title: "Complaint Investigations" },
            component: () => import("@/views/complaint/ComplaintInvestigations.vue"),
          },
          {
            path: "investigations/:investigationId(\\d+)",
            name: names.complaintInvestigationEdit,
            meta: { title: "Edit Complaint Investigation", isForm: true },
            component: () => import("@/views/complaint/ComplaintInvestigation.vue"),
          },
          {
            path: "investigations/new",
            name: names.complaintInvestigationNew,
            meta: { title: "Create Complaint Investigation", isForm: true },
            component: () => import("@/views/complaint/ComplaintInvestigation.vue"),
          },
          {
            path: "notes",
            name: names.complaintNotes,
            meta: { title: "Complaint Internal Notes" },
            component: {
              render: () => <EntityNotes idField="complaintId" routeNamePrefix="ComplaintNote" />,
            },
          },
          {
            path: "notes/:annotationId(\\d+)",
            name: names.complaintNoteEdit,
            meta: { title: "Edit Complaint Internal Note", isForm: true },
            component: () => import("@/views/complaint/ComplaintNote.vue"),
          },
          {
            path: "notes/new",
            name: names.complaintNoteNew,
            meta: { title: "Create Complaint Internal Note", isForm: true },
            component: () => import("@/views/complaint/ComplaintNote.vue"),
          },
          {
            path: "documents",
            name: names.complaintDocuments,
            meta: { title: "Complaint Documents" },
            component: {
              render: () => <EntityDocuments idField="complaintId" routeNamePrefix="ComplaintDocument" />,
            },
          },
          {
            path: "documents/:documentId(\\d+)",
            name: names.complaintDocumentEdit,
            meta: { title: "Edit Complaint Document", isForm: true },
            component: () => import("@/views/complaint/ComplaintDocument.vue"),
          },
          {
            path: "documents/new",
            name: names.complaintDocumentNew,
            meta: { title: "Create Complaint Document", isForm: true },
            component: () => import("@/views/complaint/ComplaintDocuments.vue"),
          },
          {
            path: "activity",
            name: names.complaintAudit,
            meta: { title: "Complaint Audit Activity" },
            component: {
              render: () => <EntityAudits entityName="Complaint" />,
            },
          },
          // {
          //   path: "affiliations",
          //   name: "ComplaintAffiliations",
          //   meta: { title: "Complaint Affiliations" },
          //   component: () => import("@/views/complaint/ComplaintAffiliations.vue"),
          // },
          // {
          //   path: "affiliations/:affiliationId(\\d+)",
          //   name: "ComplaintAffiliationEdit",
          //   meta: { title: "Edit Complaint Affiliation", isForm: true },
          //   component: () => import("@/views/complaint/ComplaintAffiliation.vue"),
          // },
          // {
          //   path: "affiliations/new",
          //   name: "ComplaintAffiliationNew",
          //   meta: { title: "Create Complaint Affiliation", isForm: true },
          //   component: () => import("@/views/complaint/ComplaintAffiliation.vue"),
          // },
        ],
      },
    ],
  },
];
export default complaintRoutes;
