import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, Paging, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";

import { apiHosts } from "@/api/constants/apiHosts";
import { FacilitySiteUser, FacilitySiteUserNew } from "@/views/facilitySite/facilitySite";
import type { AxiosResponse } from "axios";

const URL = (facilitySiteId: string | number) => `facility-sites/${facilitySiteId}/users`;
const ENTITY_NAME = "User";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<FacilitySiteUser, Paging<FacilitySiteUser>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getFacilitySiteUsers = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL(params.facilitySiteId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
// const getFacilitySiteUserById = (id, config: PagingApiAndNotifierConfig = {}) => {
// const successNotifCb = (options: NotifyOptionsSuccess) => {
//   options.hide = true;
//   options.fetched = ENTITY_NAME;
//   config?.successNotifCb?.(options);
// };
// const errorNotifCb = (options: NotifyOptionsError) => {
//   options.fetched = ENTITY_NAME;
//   config?.errorNotifCb?.(options)
// };
//   return api.get(URL + "/" + id, {
// successNotifCb,
// errorNotifCb,
//   });
// };

const getNewFacilitySiteUser = async () => {
  //optionally make any other changes as necessary before returning.
  return { data: {} } as AxiosResponse<FacilitySiteUserNew, any>;
};

const createFacilitySiteUser = (facilitySiteUser: FacilitySiteUser, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.title = `${ENTITY_NAME} Added`;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(facilitySiteUser.facilitySiteId), facilitySiteUser, {
    successNotifCb,
    errorNotifCb,
  });
};

// const updateFacilitySiteUser = (facilitySiteUser, config: PagingApiAndNotifierConfig = {}) => {
// const successNotifCb = (options: NotifyOptionsSuccess) => {
//   options.updated = ENTITY_NAME;
//   config?.successNotifCb?.(options);
// };
// const errorNotifCb = (options: NotifyOptionsError) => {
//   options.updated = ENTITY_NAME;
//   config?.errorNotifCb?.(options)
// };
//   return api.put(URL + "/" + facilitySiteUser.id, facilitySiteUser, {
// successNotifCb,
// errorNotifCb,
//   });
// };

/**
 * @param {FacilitySiteUser} facilitySiteUser This cannot be a 'ref' to a facilitySiteUser
 */
// const saveFacilitySiteUser = (facilitySiteUser, config: PagingApiAndNotifierConfig = {}) => {
//   if (facilitySiteUser.id) {
//     return updateFacilitySiteUser(facilitySiteUser, config);
//   } else {
//     return createFacilitySiteUser(facilitySiteUser, config);
//   }
// };

/**
 * @param {Int} id
 */
const deleteFacilitySiteUserById = (fsu: FacilitySiteUser, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.title = `${ENTITY_NAME} Removed`;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fsu.facilitySiteId) + "/" + fsu.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteFacilitySiteUserById,
  getFacilitySiteUsers,
  // getFacilitySiteUserById,
  getNewFacilitySiteUser,
  createFacilitySiteUser,
  // updateFacilitySiteUser,
  // saveFacilitySiteUser,
};
