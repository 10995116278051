import inspectionService from "@/views/inspection/inspectionService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, Paging, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { mapGetter, serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { getFacilitySiteById } from "@/views/facilitySite/facilitySiteApi";
import { getChecklistItems } from "@/services/endpoints/checklistItemsApi";
import { enums } from "@/constants/enums";
import { Inspection, InspectionNew } from "@/views/inspection/inspection";
import { isAxiosError, type AxiosResponse } from "axios";

const URL = "inspections";
const ENTITY_NAME = "Inspection";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<Inspection, Paging<Inspection>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getInspections = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getInspectionById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewInspection = async (facilitySiteId?: string | number) => {
  if (facilitySiteId) {
    return getFacilitySiteById(facilitySiteId).then((result) => {
      //TODO:TS This was previously hiding all notifs for this call. revisit
      const data = inspectionService.getInitialData();
      data.facilitySite = result.data;
      data.facilitySiteId = result.data.id;
      data.type = mapGetter(enums.facilitySiteTypes.enums, data.facilitySite.type)?.inspectionType!; //TODO:TS
      return getChecklistItems({ inspectionType: data.type }).then((response) => {
        data.checklist = [];
        response.data._items.forEach((val: any) => {
          //TODO:TS
          val.key = val.id;
          delete val.id;
          data.checklist.push(val);
        });
        return { data } as AxiosResponse<InspectionNew, any>;
      });
    });
  } else {
    const data = inspectionService.getInitialData() as InspectionNew;
    data.type = undefined;
    data.checklist = [];
    return { data } as AxiosResponse<InspectionNew, any>;
  }
};

const createInspection = (inspection: Inspection, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, inspection, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateInspection = (inspection: Inspection, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + inspection.id, inspection, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Inspection} inspection This cannot be a 'ref' to a inspection
 */
const saveInspection = (inspection: Inspection, config: PagingApiAndNotifierConfig = {}) => {
  if (inspection.id) {
    return updateInspection(inspection, config);
  } else {
    return createInspection(inspection, config);
  }
};

/**
 * @param {Int} id
 */
const deleteInspectionById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteInspectionById,
  getInspections,
  getInspectionById,
  getNewInspection,
  createInspection,
  updateInspection,
  saveInspection,
};
