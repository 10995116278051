import { enums } from "@/constants/enums";
import { PermitVersion } from "@/views/permitVersion/permitVersion";

const { permitTypes } = enums;

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: PermitVersion) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as PermitVersion;
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: PermitVersion }) {
  response.data.hasExpiration = permitTypes.get(response.data.permit?.type)?.hasExpiration;
  addAuthRequestFields(response.data);
  return response.data;
}
function requestAdapter(data: PermitVersion) {
  delete data._authRequestFields;
  //@ts-ignore //TODO:TS
  delete data.annotations;
  delete data.facilitySite;
  delete data.permit;

  if (!data.issuedDate) data.issuedDate = null;
  if (!data.effectiveDate) data.effectiveDate = null;
  if (!data.expirationDate) data.expirationDate = null;

  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: any) {} //TODO:TS

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: any) {} //TODO:TS
