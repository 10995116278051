import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";

import { apiService, Paging, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";

import { apiHosts } from "@/api/constants/apiHosts";

import { MaterialType } from "@/views/materialAccepted/materialAccepted";

const URL = "material-types";
const ENTITY_NAME = "MaterialType";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<MaterialType, Paging<MaterialType>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO fix)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getMaterialTypes = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getMaterialTypeById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export { getMaterialTypes, getMaterialTypeById };
