import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, Paging, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import type { AxiosResponse } from "axios";
import { MaterialAccepted } from "./materialAccepted";

const URL = (facilitySiteId: string | number) => `facility-sites/${facilitySiteId}/materials-accepted`;
const ENTITY_NAME = "Material Accepted";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<MaterialAccepted, Paging<MaterialAccepted>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 * @param {ApiOptions} config
 */
const getMaterialsAccepted = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL(params.facilitySiteId);
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const createMaterialAccepted = (materialAccepted: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME; // Spcial case where a "Create" is always treated as an Update because it performs CUD.
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(materialAccepted.facilitySiteId), materialAccepted, {
    successNotifCb,
    errorNotifCb,
  });
};

// const updateMaterialAccepted = (materialAccepted, config: PagingApiAndNotifierConfig = {}) => {
//   const successNotifCb = (options: NotifyOptionsSuccess) => {
//     options.updated = ENTITY_NAME;
//     config?.successNotifCb?.(options);
//   };
//   const errorNotifCb = (options: NotifyOptionsError) => {
//     options.updated = ENTITY_NAME;
//     config?.errorNotifCb?.(options)
//   };

//   return api.put(URL + "/" + materialAccepted.id, materialAccepted, {
//     successNotifCb,
//     errorNotifCb,
//   });
// };

// /**
//  * @param {MaterialAccepted} materialAccepted This cannot be a 'ref' to a materialAccepted
//  */
// const saveMaterialAccepted = (materialAccepted, config: PagingApiAndNotifierConfig = {}) => {
//   if (materialAccepted.id) {
//     return updateMaterialAccepted(materialAccepted, config);
//   } else {
//     return createMaterialAccepted(materialAccepted, config);
//   }
// };

export {
  getMaterialsAccepted,
  createMaterialAccepted,
  // updateMaterialAccepted,
  // saveMaterialAccepted,
};
