import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, Paging, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";

import { apiHosts } from "@/api/constants/apiHosts";
import { FinancialAssurance, FinancialAssuranceNew } from "@/views/permit/permit";
import type { AxiosResponse } from "axios";

const URL = (permitId: string | number) => `permits/${permitId}/financial-assurances`;
const ENTITY_NAME = "Financial Assurance";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<FinancialAssurance, Paging<FinancialAssurance>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getFinancialAssurances = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  const url = config.pagingUrl ? config.pagingUrl : URL(params.permitId);

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getFinancialAssuranceById = (ids: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.get(URL(ids.permitId) + "/" + ids.id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewFinancialAssurance = async (ids: any) => {
  //TODO:TS
  //optionally make any other changes as necessary before returning.
  return { data: { ...ids } } as AxiosResponse<FinancialAssuranceNew, any>;
};

const createFinancialAssurance = (financialAssurance: FinancialAssurance, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL(financialAssurance.permitId), financialAssurance, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateFinancialAssurance = (financialAssurance: FinancialAssurance, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  return api.put(URL(financialAssurance.permitId) + "/" + financialAssurance.id, financialAssurance, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {FinancialAssurance} financialAssurance This cannot be a 'ref' to a financialAssurance
 */
const saveFinancialAssurance = (financialAssurance: FinancialAssurance, config: PagingApiAndNotifierConfig = {}) => {
  if (financialAssurance.id) {
    return updateFinancialAssurance(financialAssurance, config);
  } else {
    return createFinancialAssurance(financialAssurance, config);
  }
};

/**
 * @param {Int} id
 */
const deleteFinancialAssuranceById = (fa: FinancialAssurance, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL(fa.permitId) + "/" + fa.id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteFinancialAssuranceById,
  getFinancialAssurances,
  getFinancialAssuranceById,
  getNewFinancialAssurance,
  createFinancialAssurance,
  updateFinancialAssurance,
  saveFinancialAssurance,
};
