import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { FileUpload } from "@modernary/mui/components/document";
import { apiHosts } from "@/api/constants/apiHosts";

const URL = "FileUpload";
const ENTITY_NAME = "File";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<FileUpload>(apiHosts.SMART, axiosParams);

/**
 * @param {String} keywords Matches against first name, last name, email
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
// const getFileUploads = (keywords, abortMethod, config: PagingApiAndNotifierConfig = {}) => {
//   return api.get(URL, {
//     params: { keywords },
//     abort: config?.abortMethod,
//     successConfig: { hide: true },
//     errorConfig: { hide: hideNotifications, fetched: ENTITY_NAME },
//   });
// };

/**
 * @param {Object} params Query parameters, varies (TODO fix)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getFileUploads = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.get(url, {
    params,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 */
const getFileUploadById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewFileUpload = async () => {
  //optionally make any other changes as necessary before returning.
  return { dataRef: {} };
};

const createFileUpload = (fileUpload: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const data = new FormData();
  fileUpload.files.forEach((file: any) => data.append("files", file.file));

  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, data, {
    onUploadProgress: function (progressEvent: ProgressEvent) {
      // Do whatever you want with the native progress event
      // console.log("progress", progressEvent);
    },
    headers: {
      "Content-Type": "multipart/form-data",
    },
    successNotifCb,
    errorNotifCb,
  });
};

const updateFileUpload = (fileUpload: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL, fileUpload, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {FileUpload} fileUpload This cannot be a 'ref' to a fileUpload
 */
const saveFileUpload = (fileUpload: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  if (fileUpload.id) {
    return updateFileUpload(fileUpload, config);
  } else {
    return createFileUpload(fileUpload, config);
  }
};

const deleteFileUploadById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteFileUploadById,
  getFileUploads,
  getFileUploadById,
  getNewFileUpload,
  createFileUpload,
  updateFileUpload,
  saveFileUpload,
};
