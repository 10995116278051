import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, Paging, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { getReportScheduleById, getReportSchedules } from "@/services/endpoints/reportScheduleApi";
import { getPermitById } from "@/views/permit/permitApi";
import disposalReportService from "@/views/disposalReport/disposalReportService";
import { getFacilitySiteById } from "@/views/facilitySite/facilitySiteApi";
import { DisposalReport, DisposalReportNew } from "@/views/disposalReport/disposalReport";
import type { AxiosResponse } from "axios";

const URL = "disposal-reports";
const ENTITY_NAME = "Disposal Report";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<DisposalReport, Paging<DisposalReport>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 * @param {ApiOptions} config
 */
const getDisposalReports = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;

  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Int} id
 * @param {ApiOptions} config
 * @returns {api.get}
 */
const getDisposalReportById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewDisposalReport = async (ids: any) => {
  //TODO:TS
  const defaultDr = { data: { ...disposalReportService.getInitialData(), ...ids } } as AxiosResponse<
    DisposalReportNew,
    any
  >;

  const permit = new Promise((resolve) => {
    resolve(ids.permitId && getPermitById(ids.permitId));
    //TODO:TS this was previously passing in a bool to hide all notifs when fetching the Permit
  });

  const reportSchedule = new Promise((resolve) => {
    resolve(ids.reportScheduleId && getReportScheduleById(ids.reportScheduleId));
    //TODO:TS this was previously passing in a bool to hide all notifs when fetching the Permit
  });

  const reportSchedules = new Promise((resolve) => {
    resolve(
      !ids.reportScheduleId &&
        ids.permitId &&
        getReportSchedules({ effectiveForPermitId: ids.permitId, reportType: "DisposalReport" }),
    );
  });

  if (ids.permitId || ids.reportScheduleId) {
    return Promise.all([permit, reportSchedules, reportSchedule]).then(
      ([permitData, reportSchedulesData, rsData]: any) => {
        //TODO:TS
        defaultDr.data.permit = permitData?.data;
        if (permitData?.data) {
          defaultDr.data.facilitySiteId = permitData.data.facilitySiteId;
          defaultDr.data.facilitySite = permitData.data.facilitySite;
        }

        //Used on the Select screen.
        defaultDr.data.reportSchedules = reportSchedulesData?.data?._items.filter(
          (x: any) => x.reportType === "DisposalReport",
          //TODO:TS
        );
        defaultDr.data.reportSchedule = rsData?.data;

        if (rsData?.data) {
          // initialize fields based on schedule.
          defaultDr.data.type = rsData.data.reportType;
          defaultDr.data.frequency = rsData.data.frequency;
        }
        return defaultDr;
      },
    );
  } else {
    return defaultDr;
  }
};

/**
 *
 * @param {Object} ids Object having either an `id` for a DisposalReport or a `facilitySiteId`
 * @param {ApiOptions} config
 * @returns {api.get}
 */

const createDisposalReport = (disposalReport: DisposalReport, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, disposalReport, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateDisposalReport = (disposalReport: DisposalReport, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + disposalReport.id, disposalReport, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {DisposalReport} disposalReport This cannot be a 'ref' to a disposalReport
 */
const saveDisposalReport = (disposalReport: DisposalReport, config: PagingApiAndNotifierConfig = {}) => {
  if (disposalReport.id) {
    return updateDisposalReport(disposalReport, config);
  } else {
    return createDisposalReport(disposalReport, config);
  }
};

/**
 * @param {Int} id
 */
const deleteDisposalReportById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export {
  deleteDisposalReportById,
  getDisposalReports,
  getDisposalReportById,
  getNewDisposalReport,
  createDisposalReport,
  updateDisposalReport,
  saveDisposalReport,
};
