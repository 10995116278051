import { Application } from "@/views/application/application";

export default {
  addAuthRequestFields,
  getInitialData,
  responseAdapter,
  requestAdapter,
  fromFormkit,
  toFormkit,
};

function addAuthRequestFields(entity: Application) {
  entity._authRequestFields = ["id", "status"];
  return entity;
}
function getInitialData() {
  const result = {} as Application; //TODO: ts
  return addAuthRequestFields(result);
}
function responseAdapter(response: { data: Application }) {
  addAuthRequestFields(response.data);
  return response.data;
}
function requestAdapter(data: Application) {
  return data;
}

// Convert server data for use in the page's form (which may have groupings, etc different from the server data)
function toFormkit(data: Application) {}

// Convert formkit data to prep it for sending to the server (which is probably flatter than the fk data)
function fromFormkit(data: Application) {}
