import reportService from "@/views/report/reportService";
import { NotifyOptionsSuccess, NotifyOptionsError } from "@modernary/mui/components/notifications";
import { apiService, Paging, PagingApiAndNotifierConfig } from "@modernary/mui/utils/api";
import { serializeQueryParams } from "@modernary/mui/utils/helpers";
import { apiHosts } from "@/api/constants/apiHosts";
import { getReportScheduleById, getReportSchedules } from "../../services/endpoints/reportScheduleApi";
import { getPermitById } from "@/views/permit/permitApi";
import { Report, ReportNew } from "@/views/report/report";
import type { AxiosResponse } from "axios";

const URL = "generic-reports";
const ENTITY_NAME = "Report";

const axiosParams = {
  // Set different base URL based on the environment
  baseURL: import.meta.env.VITE_SMART_API_BASE_URL,
};
const api = apiService<Report, Paging<Report>>(apiHosts.SMART, axiosParams);

/**
 * @param {Object} params Query parameters, varies (TODO improve this)
 * @param {abortCallback} abortMethod Callback for when a request has been aborted
 */
const getReports = (params: any, config: PagingApiAndNotifierConfig = {}) => {
  //TODO:TS
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };
  const url = config.pagingUrl ? config.pagingUrl : URL;
  return api.getPaged(url, {
    params,
    paramsSerializer: serializeQueryParams,
    abort: config?.abortMethod,
    successNotifCb,
    errorNotifCb,
  });
};

const getReportById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.hide = true;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.fetched = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.get(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

const getNewReport = async (ids: any) => {
  const defaultReport = {
    data: {
      ...reportService.getInitialData(),
      ...ids,
      customDueDate: false, //defaulting the custom due date decision to false for new reports. Not used on report edits
      //receivedDate: new Date().toISOString().split("T")[0],
    },
  } as AxiosResponse<ReportNew, any>;

  const permit = new Promise((resolve) => {
    resolve(ids.permitId && getPermitById(ids.permitId));
    //TODO:TS this was previously hiding all notifs
  });

  const reportSchedule = new Promise((resolve) => {
    resolve(ids.reportScheduleId && getReportScheduleById(ids.reportScheduleId));
    //TODO:TS this was previously hiding all notifs
  });

  const reportSchedules = new Promise((resolve) => {
    resolve(!ids.reportScheduleId && ids.permitId && getReportSchedules({ effectiveForPermitId: ids.permitId }));
  });

  if (ids.permitId || ids.reportScheduleId) {
    return Promise.all([permit, reportSchedules, reportSchedule]).then(
      ([permitData, reportSchedulesData, rsData]: any) => {
        //TODO:TS
        defaultReport.data.permit = permitData?.data;
        if (permitData?.data) {
          defaultReport.data.facilitySiteId = permitData.data.facilitySiteId;
          defaultReport.data.facilitySite = permitData.data.facilitySite;
        }

        //Used on the Select screen.
        defaultReport.data.reportSchedules = reportSchedulesData?.data?._items.filter(
          (x: any) => x.reportType !== "DisposalReport",
        );
        defaultReport.data.reportSchedule = rsData?.data;

        if (rsData?.data) {
          // initialize fields based on schedule.
          defaultReport.data.type = rsData.data.reportType;
          defaultReport.data.frequency = rsData.data.frequency;
          defaultReport.data.dueDate = rsData.data.dueDate;
        }
        return defaultReport;
      },
    );
  } else {
    return defaultReport;
  }
};

/**
 *
 * @param {Object} ids Object having either an `id` for an Report or a `facilitySiteId`, `permitId`, and type
 * @param {ApiOptions} config
 * @returns {api.get}
 */

const createReport = (report: Report, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.created = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.created = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.post(URL, report, {
    successNotifCb,
    errorNotifCb,
  });
};

const updateReport = (report: Report, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.updated = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.updated = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.put(URL + "/" + report.id, report, {
    successNotifCb,
    errorNotifCb,
  });
};

/**
 * @param {Report} report This cannot be a 'ref' to a report
 */
const saveReport = (report: Report, config: PagingApiAndNotifierConfig = {}) => {
  if (report.id) {
    return updateReport(report, config);
  } else {
    return createReport(report, config);
  }
};

const deleteReportById = (id: string | number, config: PagingApiAndNotifierConfig = {}) => {
  const successNotifCb = (options: NotifyOptionsSuccess) => {
    options.deleted = ENTITY_NAME;
    config?.successNotifCb?.(options);
  };
  const errorNotifCb = (options: NotifyOptionsError) => {
    options.deleted = ENTITY_NAME;
    config?.errorNotifCb?.(options);
  };

  return api.delete(URL + "/" + id, {
    successNotifCb,
    errorNotifCb,
  });
};

export { deleteReportById, getReports, getReportById, getNewReport, createReport, updateReport, saveReport };
